<script>
  import { onMount, onDestroy } from "svelte";
  import { Link } from "svelte-routing";

  onMount(() => {
    document.body.classList.add("align-center");
  });
  onDestroy(() => {
    document.body.classList.remove("align-center");
  });
</script>

<div>
  <h1
    style="text-transform: capitalize;
    font-weight: 700;
    color: #939191;"
  >
  Opps! 404 Page Not Found.
  </h1>
  <div
    style="text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 31px;"
  >
    <Link to="/" style="text-decoration: none!important;"
      ><span class="button-style ">Back to site</span></Link
    >
  </div>
</div>


