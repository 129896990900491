<script>
  import Crossword from "../src/Crossword.svelte";
  import dataNYTMini from "./data/nyt-mini.json";
  import dataNYTDaily from "./data/nyt-daily.json";
  import dataOreo from "./data/oreo.json";
  import dataUSA from "./data/usa.json";
  import checkMobile from "../src/helpers/checkMobile";
  import { Router, Route } from "svelte-routing";
  import Home from "../src/Home.svelte";
  import NotFound from "../src/NotFound.svelte";
  let revealedUSA;
  let theme;
  // let isMobile;
  // let showKeyboard = false;
  // isMobile = checkMobile();

  // if (isMobile) {
  //   showKeyboard = true;
  // }

  export let url = "";
  const urlParams = new URLSearchParams(window.location.search);
  const isEmbeded = urlParams.has('version');
</script>

<Router url="{url}">
  <article style="{`${
    isEmbeded == false ? '' : 'max-width: 100% !important;padding:1px 0px !important;'
  }`}">
    <section id="mobile"  style="{`${
      isEmbeded == false ? 'max-width: 825px;' : ''
    }`}">
      <Route path="/error-404" exact component="{NotFound}" />

      <!-- <Crossword data="{dataNYTMini}" showKeyboard="{showKeyboard}" /> -->
    </section>
    <Route path="/:url" component="{Crossword}" />

  </article>
  <Route path="/" component="{Home}" />
</Router>

<style>

  article {
    max-width: 960px;
    margin: 0 auto;
    padding: 1em;
    font-family: sans-serif;
  }
  /* .intro {
    text-align: left;
    max-width: 760px;
    font-size: 1.5em;
  } */
  section {
    max-width: 960px;
    margin: 1em 0;
  }
  /* 
  .info {
    max-width: 640px;

    text-align: left;
    font-size: 1.125em;
  } */
  /* .info a {
    margin-right: 0.25em;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 123.5%;



    color: #000000;
  }
  p {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 123.5%;

  }

  #themes {
    padding: 1em;
  }

  #themes.dark {
    background: #1a1a1a;
    color: #fff;
  }
  code {
    font-size: 0.85em;
    background-color: #efefef;
    padding: 0 0.25em;
  }

  button {
    cursor: pointer;
  } */
  @media only screen and (max-width: 720px) {
    /* keyboard styling */
  article {
    padding-top: 10px !important;
  }
  :global(.keyboard button){
    border: 1px solid white !important;
    background:white !important;
    color: black !important;
    margin: 5px 2.5px !important;
    font-size: 22px !important;
    width: 8.2% !important;
  }
  :global(.keyboard){
    background:#e4eaef !important;
  }
  :global(.toolbar){
   padding-top: 0px !important;
  }
  :global(.clue-bar-margin-top){
    margin-top: 10px !important;
  }

  /* :global(body){
    font-family: 'Comic Sans MS', cursive !important;
  } */
}

@media only screen and (max-width: 425px) {
  /* article { */
    /* height: 100%;
    max-height: 440px;
    overflow: scroll; */
  /* } */
  :global(.keyboard button){
    margin: 4px 2.5px !important;
    font-size: 20px !important;
    width: 8% !important;
  }
  :global(.clue-bar-margin-top .bar){
    padding: 13px 0px !important;
  }
}
</style>
