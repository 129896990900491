<script>
  import scrollTo from "./helpers/scrollTo.js";

  export let number;
  export let clue;
  export let news_url;
  export let custom;
  export let isFilled;
  export let isNumberFocused = false;
  export let isDirectionFocused = false;
  export let isDisableHighlight = false;
  export let onFocus = () => {};
  export let fontFamily;

  let element;

  $: isFocused = isNumberFocused;
  $: clueLink = true;
</script>

<li bind:this="{element}" use:scrollTo="{isFocused}" style="  display: flex;
flex-direction: row;
align-items: stretch;">
  <!-- href="{news_url ? '#' : '#'}" -->
  <!-- target="{news_url ? '_blank' : ''}" -->
  <span
    style:font-family={fontFamily ? fontFamily : ''}
    class="clue {custom} no-underline fix-width"
    class:is-disable-highlight="{isDisableHighlight}"
    class:is-number-focused="{isNumberFocused}"
    class:clu-link="{clueLink}"
    class:is-direction-focused="{isDirectionFocused}"
    class:is-filled="{isFilled}"
    on:click="{onFocus}"
  >
    <strong>{number}</strong>
    {clue}
    {#if news_url}
    <a href="{news_url ? news_url : '#'}" target="_parent"><img src="{`/external_link.png`}" class="external_link_icon" alt="News article"/></a>
    {/if}
  </span>

</li>

<style>
  .fix-width{
    max-width: 300px;
  }
  .external_link_icon{
    width: 15px;
    /* margin-right: 10px; */
    margin-left: 10px;
    margin-top: -3px;
    /* height: 15px;
    display: inline-block;
    float: right;
    margin-top: -25px; */
    /* margin-top: 20px; */
  }
  /* button {
    display: flex;
    width: 100%;
    background: none;
    text-align: left;
    appearance: none;
    outline: none;
    border: none;
    border-left: 6px solid transparent;
    padding: 0.5em;
    cursor: pointer;
    line-height: 1.325;
    color: var(--main-color);
    font-family: var(--font);
    font-size: 1em;
    cursor: pointer;
  } */
  .underline {
    text-decoration: underline !important;
  }
  .no-underline {
    text-decoration: none !important;
  }
  .clu-link {
    /* text-decoration: none !important; */
    display: flex;
    width: 100%;
    background: none;
    text-align: left;
    appearance: none;
    outline: none;
    border: none;
    border-left: 6px solid transparent;
    padding: 0.5em;
    cursor: pointer;
    line-height: 1.325;
    color: var(--main-color);
    font-family: var(--font);
    font-size: 1em;
    cursor: pointer;
  }

  strong {
    min-width: 1.25em;
    display: inline-block;
    text-align: right;
    margin-right: 0.5em;
  }


  .clue:focus:not(.is-disable-highlight) {
    border-color: var(--secondary-highlight-color);
  }
  .is-number-focused:not(.is-disable-highlight) {
    border-left-color: var(--secondary-highlight-color);
  }
  .is-number-focused.is-direction-focused:not(.is-disable-highlight) {
    background: var(--secondary-highlight-color);
  }
  .is-filled {
    opacity: 0.5;
  }
</style>
