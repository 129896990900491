<div style="margin-top: 50px">
  <div style="text-align: center">
    <div class="image-center">
      <img src="{`/logo.png`}" alt="News Crossword" style="width: 170px" />
    </div>
    <h2 class="cross-word-title" style="    margin-top: 20px;">News Crossword</h2>
    <p class="home-discription">Create your own mini crossword puzzles.</p>
    <div class="image-center">
      <a href="/member-area/register" class="signBtn" style="    margin-top: 16px;">
        <span class="sign-up-text">Sign up</span>
      </a>
    </div>
    <div style="margin-top: 22px">
      <a href="/member-area/login" class="loginLink">
        <span>Log in</span>
      </a>
    </div>

    <div>
      <div class="bottom-text">
        <div style="    margin-bottom: 15px;">
          <a
            href="https://www.newsgames.org/about"
            style=" color: #a7a7a7;text-decoration: underline"
            target="_blank"
          >
            About
          </a>
          &nbsp;&nbsp;<b>.</b>&nbsp;&nbsp;
          <a
            href="https://www.newsgames.org/terms"
            style=" color: #a7a7a7;text-decoration: underline"
            target="_blank"
          >
            Terms
          </a>
          &nbsp;&nbsp;<b>.</b>&nbsp;&nbsp;
          <a
            href="https://www.newsgames.org/privacy"
            style=" color: #a7a7a7;text-decoration: underline"
            target="_blank"
          >
            Privacy
          </a>
          &nbsp;&nbsp;
        </div>
        <span style="    margin-left: -9px;">© 2022 <a href="https://www.newsgames.org" style="color: rgb(167, 167, 167); text-decoration: underline;"  target="_blank">News Games</a></span>
      </div>
    </div>
  </div>
</div>

<style>
  .image-center {
    text-align: center;
    justify-content: center;
    display: flex;
  }
  .cross-word-title{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 57px;
    text-align: center;
    color: #000000;
  }

  .home-discription {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;

    color: #000000;
  }
  @media (max-width: 500px) {
    .home-discription {
      font-size: 23px;
    }
  }

  .signBtn {
    background: #47a1f4;
    color: white;
    text-decoration: none !important;
    height: 57px;
    width: 267px;

    font-family: Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 35.3px;
    color: #ffffff;
    display: block;
    text-align: center;
    border-radius: 10px;
  }
  .sign-up-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .loginLink {
    font-family: Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 25.3px;
    color: #47a1f4;
    text-decoration: none!important;
    text-align: center;
  }

  .bottom-text {
    position: absolute;
    left: 51%;
    top: 99%;
    transform: translate(-50%, -99%);
    font-weight: 400;
    font-size: 12px;
    line-height: 13.8px;
    font-family: Arial;
    color: #a7a7a7;
    text-align: center;
  }
</style>
