export default {
	"font": "sans-serif",
	"primary-highlight-color": "#ffda00",
	"secondary-highlight-color": "#a7d8ff",
	"main-color": "#1a1a1a",
	"bg-color": "#fff",
	"accent-color": "#efefef",
	"scrollbar-color": "#cdcdcd",
	"order": "row"
};
