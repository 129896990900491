<script>
  import { fade } from "svelte/transition";
  // import Confetti from "./Confetti.svelte";
  import { Confetti } from "svelte-confetti";
  import { confetti } from '@neoconfetti/svelte';
  // import { browser } from '$app/environment';
  export let showConfetti = false;
  
 export let isOpen = true;
 export let NotIncognito = true;
//  export let isWordConnectionsShow = false;
 export let keepTrying;
 export let isTryAgain = false;
   //here i am checking if the query paramter have embebed version parameter
   let isEmbeded;
  //  if (browser) {
    const urlParams = new URLSearchParams(window?.location?.search);
   isEmbeded = urlParams.has('version');
  //  }
</script>
<!-- on:click="{() => (isOpen = false)}" -->
{#if isOpen}
  <div class="completed {isEmbeded==true ? 'complete-embeded':''}" transition:fade="{{ y: 20 }}" style="{NotIncognito == false ? 'top:142px !important;':''}">
    <div class="content" style="{NotIncognito == false ? 'padding: 25px 20px 10px 20px  !important;':''}">
      <!-- {#if isWordConnectionsShow && NotIncognito} -->
      <div
        style="    width: 100%; text-align: right;  margin-top: -18px;"
      >
        {#if isTryAgain}
          <img src="/cross-icon.png" on:click={keepTrying} on:keypress={keepTrying}  style="cursor: pointer;" alt="cross"/>
        {/if}

        {#if !isTryAgain}
          <img src="/cross-icon.png"  on:click="{() => (isOpen = false)}" on:keypress="{() => (isOpen = false)}"  style="cursor: pointer;" alt="cross"/>
        {/if}
      </div>
      <!-- {/if} -->
      <div class="message" style="    width: 100%;">
        <slot />
      </div>
      <!-- <button on:click="{() => (isOpen = false)}">View puzzle</button> -->
    </div>
    <!-- https://github.com/puruvj/neoconfetti/tree/main/packages/svelte#readme -->
    {#if showConfetti}
    
      <div class="confetti">
        <!-- ,stageHeight: 1000,stageWidth: 1600 -->
         <div class="confitti" use:confetti={{ particleCount: 500, force: 1,particleSize:15,particleShape: 'mix',duration: 4000,colors: ["#26ccff","#a25afd","#ff5e7e","#88ff5a","#fcff42","#ffa62d","#ff36ff"],stageHeight: 800,stageWidth: 1200}} />
      </div> 
    {/if}
  </div>
  <!-- <div
    class="curtain"
    transition:fade="{{ duration: 250 }}"
    on:click="{() => (isOpen = false)}"
  ></div> -->
{/if}

<style>
  /* :global(body) {
		overflow: scroll;

	} */

	.confitti {
		display: flex;
		justify-content: center;
    
	}
  .word-connections{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
  }
  .completed {
    position: absolute;
    top: min(46%, 20em);
    left: 50%;
    background-color: var(--bg-color);
    transform: translate(-50%, -50%);
    border-radius: 4px;
    z-index: 100;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
    font-family: var(--font);
    width: 50%;
    box-shadow: 1px 2px 91px gainsboro;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 10px;
  }
  @media (max-width: 600px) {
    .completed {
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 500px) {
    .completed {
      top: min(65%, 22em);
    }
    .complete-embeded{
      top: min(60%, 16.5em) !important;
    }
  }
  .curtain {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    left: 0;
    background-color: var(--bg-color);
    opacity: 0.9;
    cursor: pointer;
    z-index: 1;
  }

  button {
    cursor: pointer;
    margin-left: 1em;
    font-size: 1em;
    font-family: var(--font);
    background-color: var(--accent-color);
    border-radius: 4px;
    color: var(--main-color);
    padding: 0.75em;
    border: none;
    font-weight: 400;
    transition: background-color 150ms;
  }

  button:hover {
    background-color: var(--secondary-highlight-color);
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }

  .message {
    margin-bottom: 1em;
  }

  .confetti {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
