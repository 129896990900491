<script>
  import { onMount, onDestroy } from "svelte";
  import checkMobile from "./helpers/checkMobile.js";
  import checkIpad from './helpers/checkIpad.js';
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  let timerTime = 0;
  let isRunning = false;
  let interval;
  let TotalTime;
  let isMobile = '';
  let isIpadDevice = false;
  export let puzzleDetail;

   //here i am checking if the query paramter have embebed version parameter

  const urlParams = new URLSearchParams(window?.location?.search);
  let isEmbeded = urlParams.has('version');
  
  export const incrementTimer = () => {
    let start;
    let puzzleDate;
    // if(isEmbeded == false){
      start = localStorage.getItem("start");
      puzzleDate = localStorage.getItem("run_date");
    // }
    // const start = localStorage.getItem("start");

    const minutes = document.querySelector(".minutes");
    const seconds = document.querySelector(".seconds");
    const timer = document.getElementById("timer");
    timerTime = parseInt(timerTime) + parseInt(1);

    const numberMinutes = Math.floor(timerTime / 60);
    const numberSeconds = timerTime % 60;

    minutes.innerText = pad(numberMinutes);
    seconds.innerText = pad(numberSeconds);
    timer.value = pad(numberMinutes) + " : " + pad(numberSeconds);
    TotalTime = pad(numberMinutes) + ":" + pad(numberSeconds);

    if(!start && puzzleDate!=null){
      // console.log(timerTime+ 'timerTime');/
      localStorage.setItem("timmer", timerTime);
      // localStorage.setItem("start", true);

    }
  };
  const pad = (number) => {
    return number < 10 ? "0" + number : number;
  };

  export const stop = () => {
    isRunning = false;
    clearInterval(interval);
  };
  export const getTime = () => {
    return TotalTime;
  };
  export const startTimer = () => {
    isRunning = true;
    // if(isEmbeded == false){
      const get_time = localStorage.getItem("timmer");
      if (get_time!=null) {
        // console.log(get_time+'get_time');
        timerTime = get_time;
      }
    // }

    if (isRunning) {
      interval = setInterval(incrementTimer, 1000);
    }
  };

  onMount(() => {
    // console.log(isEmbeded+'isEmbeded');
    // if(isEmbeded == false){
      isMobile = checkMobile();
      isIpadDevice = checkIpad();
      const get_time = localStorage.getItem("timmer");
      const start = localStorage.getItem("start");
      if (get_time) {
        if(start==='true')
        {
          timerTime=get_time-1 > 0 ? get_time-1 : 0;
          incrementTimer();
        }else{
          startTimer();

        }
      
      }
    // }

  });

  export const stopAndZero = () => {
    isRunning = false;
    timerTime = 0;
    const minutes = document.querySelector(".minutes");
    const seconds = document.querySelector(".seconds");
    const timer = document.getElementById("timer");
    minutes.innerText = "00";
    seconds.innerText = "00";
    timer.value = pad("00") + " : " + pad("00");
    TotalTime = pad("00") + ":" + pad("00");
  };

  export let actions = ["clear", "reveal", "check"];
  export let fontFamily;
</script>

<div class="toolbar {isIpadDevice ? 'Ipad-screen-toolbar-width':''}">
  <div class="info float-start">
    <h2 style:font-family={fontFamily ? fontFamily : ''}>
      {puzzleDetail?.formated_run_date ? puzzleDetail?.formated_run_date : ""}
    </h2>
    <p style:font-family={fontFamily ? fontFamily : ''}>By {puzzleDetail?.author ? puzzleDetail?.author : ""}</p>
  </div>
  <nav role="navigation">
    <div class="dropdown dropstart float-end flex-box">
        <!-- timer -->
        <span>
          <div class="jumbotron text-center" style:font-family={fontFamily ? fontFamily : ''}>
            <div class="timer" style="    margin-right: 13px;">
              <span class="minutes"> 00 </span>
              :
              <span class="seconds"> 00 </span>
              <input type="hidden" name="timer" id="timer" />
            </div>
          </div>
        </span>
        <!-- timer -->

        <span
          class="menu_button"
          type="button"
          aria-label="Toggle Menu" 
          role="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="menu_icon.png" aria-hidden="true" alt="" />
        </span>

        <ul role="menu" aria-label="Main Menu" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          {#each actions as action}
            {#if action === "clear"}
              <li style:font-family={fontFamily ? fontFamily : ''}
                class="dropdown-item" aria-label="Clear" role="menuitem"
                on:click="{() => dispatch('event', 'clear')}"
              >
                Clear
              </li>
            {:else if action === "reveal"}
              <li style:font-family={fontFamily ? fontFamily : ''}
                class="dropdown-item" aria-label="Reveal" role="menuitem"
                on:click="{() => dispatch('event', 'reveal')}"
              >
                Reveal
              </li>
            {:else if action === "check"}
              <li style:font-family={fontFamily ? fontFamily : ''}
                class="dropdown-item" aria-label="Check" role="menuitem"
                on:click="{() => dispatch('event', 'check')}"
              >
                Check
              </li>
            {/if}
          {/each}

        
        </ul>

    </div>
  </nav>
 
</div>

<style>
  .info {
    line-height: 123.5%;
  }
  .info p {
    font-family: "Arial";
    font-style: normal;
  }
  .info h2 {
    font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
  .toolbar {
    margin-bottom: 1em;
    padding: 1em 0;
    /* display: flex;
    justify-content: flex-end; */
    font-family: var(--font);
    font-size: 0.85em;
    background-color: transparent;
  }

  .dropdown-menu li {
    cursor: pointer;
  }

  .menu_button {
    background: none;
  }
  .flex-box {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-height: 850px) {
    .Ipad-screen-toolbar-width{
      width: 100% !important;
    }
  }

 
  @media only screen and (max-height: 810px) {
    .Ipad-screen-toolbar-width{
      width: 95%;
    }
  }



  @media only screen and (max-height: 780px) {
    .Ipad-screen-toolbar-width{
      width: 88%;
    }
  }

  @media only screen and (max-height: 750px) {
    .Ipad-screen-toolbar-width{
      width: 85%;
    }
  }

  @media only screen and (max-height: 730px) {
    .Ipad-screen-toolbar-width{
      width: 80%;
    }
  }

  @media only screen and (max-height: 700px) {

    .Ipad-screen-toolbar-width{
      width: 75%;
    }
  }


  @media only screen and (max-height: 680px) {
    .Ipad-screen-toolbar-width{
      width: 70%;
    }
  }


  @media only screen and (max-height: 650px) {
    .Ipad-screen-toolbar-width{
      width: 65%;
    }
  }


  @media only screen and (max-height: 620px) {
    .Ipad-screen-toolbar-width{
      width: 60%;
    }
  }


  
  @media only screen and (max-height: 600px) {
    .Ipad-screen-toolbar-width{
      width: 55%;
    }
  }

  @media only screen and (max-height: 580px) {
    .Ipad-screen-toolbar-width{
      width: 50%;
    }
  }

  @media only screen and (max-height: 550px) {
    .Ipad-screen-toolbar-width{
      width: 45%;
    }
  }

  @media only screen and (max-height: 530px) {
    .Ipad-screen-toolbar-width{
      width: 40%;
    }
  }

  @media only screen and (max-height: 500px) {
    .Ipad-screen-toolbar-width{
      width: 35%;
    }
  }
</style>
