<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let currentClue = {};
  export let fontFamily;
  $: clue = currentClue["clue"];
  $: news_url = currentClue["news_url"];
  $: custom = currentClue["custom"] || "";
</script>

<div class="bar {custom}">
  <button aria-label="Previous" on:click="{() => dispatch('nextClue', currentClue.index - 1)}">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-chevron-left"
    >
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  </button>
  <!-- _blank -->
  <a
   style:font-family={fontFamily ? fontFamily : ''}
    href="{news_url ? news_url : '#'}"
    target="{news_url ? '_parent' : ''}"
    class="link-style {news_url ? 'underline' : 'no-underline'}">{clue}</a
  >
  <button aria-label="Next" on:click="{() => dispatch('nextClue', currentClue.index + 1)}">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  </button>
</div>

<style>
  .bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary-highlight-color);
    align-items: center;
    padding: 15px 0px;
  }
  .underline{
    text-decoration: underline!important;
  }
  .no-underline{
    text-decoration: none!important;
  }

  .link-style {
    padding: 0 1em;
    line-height: 1.325;
    font-family: var(--font);
    margin-bottom: 0px !important;
    color: black;
  }
  button {
    cursor: pointer;
    font-size: 1em;
    border: none;
    line-height: 1;
    color: var(--main-color);
    background-color: transparent;
  }
</style>
