export default function checkMobile() {
	const devices = {
		android: () => navigator.userAgent.match(/Android/i),

		blackberry: () => navigator.userAgent.match(/BlackBerry/i),

		ios: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),

		opera: () => navigator.userAgent.match(/Opera Mini/i),

		windows: () => navigator.userAgent.match(/IEMobile/i),
		ipadPro: () => (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad',

	};

	function isIpadPro() {
		var ratio = window.devicePixelRatio || 1;
		var screen = {
			width : window.screen.width * ratio,
			height : window.screen.height * ratio
		};
		 if((screen.width === 2048 && screen.height === 2732) || (screen.width === 2732 && screen.height === 2048) || (screen.width === 1536 && screen.height === 2048) || (screen.width === 2048 && screen.height === 1536)){
			return 'iPad';
		 }else{
			return false;
		 }
	}

	return devices.android() ||
		devices.blackberry() ||
		devices.ios() ||
		devices.opera() ||
		devices.windows() || devices.ipadPro() || isIpadPro();
}
