<script>
  import { onMount } from "svelte";
  import Keyboard from "svelte-keyboard";
  import getSecondarilyFocusedCells from "./helpers/getSecondarilyFocusedCells.js";
  import getCellAfterDiff from "./helpers/getCellAfterDiff.js";
  import checkMobile from "./helpers/checkMobile.js";
  import checkIpad from './helpers/checkIpad.js';
  import ClueBar from "./ClueBar.svelte";
  import Cell from "./Cell.svelte";
  import Toolbar from "./Toolbar.svelte";
  import { createEventDispatcher } from "svelte";

  export let clues;
  export let disabled;
  export let cells;
  export let focusedDirection;
  export let focusedCellIndex;
  export let focusedCell;
  export let isRevealing;
  export let isChecking;
  export let isDisableHighlight;
  export let stacked;
  export let revealDuration = 1000;
  export let showKeyboard;
  export let fontFamily;
  export let isLoaded;
  export let keyboardStyle;
  export let puzzleDetail;
  let toolBarData;
  let clearPuzzleNowActiveFirstBox;

  //these are for toolbar
  export let actions = ["clear", "reveal", "check"];
  export let revealed = false;
  let revealTimeout;

  //these are for slider

  export let cellIndexMap;

  
   //here i am checking if the query paramter have embebed version parameter
  const urlParams = new URLSearchParams(window?.location?.search);
  const isEmbeded = urlParams.has('version');


  let element;
  let run = true;
  let cellsHistoryIndex = 0;
  let cellsHistory = [];
  let focusedCellIndexHistoryIndex = 0;
  let focusedCellIndexHistory = [];
  let secondarilyFocusedCells = [];
  let isMobile = false;
  let isPuzzleFocused = false;
  let isIpadDevice = false;
  const numberOfStatesInHistory = 10;
  $: w = Math.max(...cells.map((d) => d.x)) + 1;
  $: h = Math.max(...cells.map((d) => d.y)) + 1;
  $: timmerStart = false;
  $: alreadyFilledData = [];
  $: keyboardVisible =
    typeof showKeyboard === "boolean" ? showKeyboard : isMobile;

  $: cells, focusedCellIndex, focusedDirection, updateSecondarilyFocusedCells();
  $: sortedCellsInDirection = [...cells].sort((a, b) =>
    focusedDirection == "down" ? a.x - b.x || a.y - b.y : a.y - b.y || a.x - b.x
  );

  onMount(() => {
    isMobile = checkMobile();
    isIpadDevice = checkIpad();

    // alert(isMobile);
    let isWon = localStorage.getItem('w');
    let start = localStorage.getItem('start');
    let isReveal = localStorage.getItem('isReveal');
    let keyword;
 
    keyword = localStorage.getItem("keyword");  
    if (isMobile && isWon == 'y') {
      showKeyboard = false;
    }
    // if (isMobile && start == 'true') {
    //   showKeyboard = false;
    // }
    // if (isMobile && isReveal == 'true') {
    //   showKeyboard = true;
    // }
    dispatch("toolBarData", {
      toolBarData: toolBarData,
    });
  
  
    
    if (keyword !=null || alreadyFilledData.length) {
      alreadyFilledData = JSON.parse(keyword);
      const allCells = cells;
      for (const item of allCells) {
        const find = alreadyFilledData.find((data) => {
          return data.index === item.index;
        });
        if (find) {
          const value = find.value;
          cells[item.index].value = value;
        }
      }
    }
  });

  function updateSecondarilyFocusedCells() {
    secondarilyFocusedCells = getSecondarilyFocusedCells({
      cells,
      focusedDirection,
      focusedCell,
    });
  }
  const dispatch = createEventDispatcher();

  function onCellUpdate(index, newValue, diff = 1, doReplaceFilledCells) {
    doReplaceFilledCells = doReplaceFilledCells || !!cells[index].value;

    if(keyboardVisible==true){
      let data = getToStorage();
      if (data.length) {
        const object = { index: index, value: newValue.toUpperCase() };
        data = data.filter(item => item.index !== index);
        if(newValue){
          data.push(object);

        }
        setToStorage(data);
       
      } else {
        const array = [{ index: index, value: newValue.toUpperCase() }];
        if(newValue){
          setToStorage(array);

        }
      }
    }
    const dimension = focusedDirection == "across" ? "x" : "y";
    const clueIndex = cells[index].clueNumbers[focusedDirection];
    const cellsInClue = cells.filter(
      (cell) =>
        cell.clueNumbers[focusedDirection] == clueIndex &&
        (doReplaceFilledCells || !cell.value)
    );
    const cellsInCluePositions = cellsInClue
      .map((cell) => cell[dimension])
      .filter(Number.isFinite);
    const isAtEndOfClue =
      cells[index][dimension] == Math.max(...cellsInCluePositions);

    const newCells = [
      ...cells.slice(0, index),
      { ...cells[index], value: newValue },
      ...cells.slice(index + 1),
    ];
    cellsHistory = [newCells, ...cellsHistory.slice(cellsHistoryIndex)].slice(
      0,
      numberOfStatesInHistory
    );
    cellsHistoryIndex = 0;
    cells = newCells;

    if (isAtEndOfClue && diff > 0) {
      onFocusClueDiff(diff);
    } else {
      onFocusCellDiff(diff, doReplaceFilledCells);
    }

    // if(isEmbeded == false){
      const timmer = localStorage.getItem("timmer");
      if (!timmer) {
        if (run == true) {
          toolBarData.startTimer();
          run = false;
        }
      }
    // }else{
    //   if (run == true) {
    //       toolBarData.startTimer();
    //       run = false;
    //     }
    // }

  }

  function onHistoricalChange(diff) {
    cellsHistoryIndex += -diff;
    cells = cellsHistory[cellsHistoryIndex] || cells;
    focusedCellIndexHistoryIndex += -diff;
    focusedCellIndex =
      focusedCellIndexHistory[cellsHistoryIndex] || focusedCellIndex;
  }

  function onFocusCell(index) {
    if (isPuzzleFocused && index == focusedCellIndex) {
      onFlipDirection();
    } else {
      focusedCellIndex = index;

      if (!cells[focusedCellIndex].clueNumbers[focusedDirection]) {
        const newDirection = focusedDirection === "across" ? "down" : "across";
        focusedDirection = newDirection;
      }

      focusedCellIndexHistory = [
        index,
        ...focusedCellIndexHistory.slice(0, numberOfStatesInHistory),
      ];
      focusedCellIndexHistoryIndex = 0;
    }
  }

  function onFocusCellDiff(diff, doReplaceFilledCells = true) {
    const sortedCellsInDirectionFiltered = sortedCellsInDirection.filter((d) =>
      doReplaceFilledCells ? true : !d.value
    );
    const currentCellIndex = sortedCellsInDirectionFiltered.findIndex(
      (d) => d.index == focusedCellIndex
    );
    const nextCellIndex = (
      sortedCellsInDirectionFiltered[currentCellIndex + diff] || {}
    ).index;
    const nextCell = cells[nextCellIndex];
    if (!nextCell) return;
    onFocusCell(nextCellIndex);
  }

  function onFocusClueDiff(diff = 1) {
    const currentNumber = focusedCell.clueNumbers[focusedDirection];
    let nextCluesInDirection = clues.filter(
      (clue) =>
        !clue.isFilled &&
        (diff > 0
          ? clue.number > currentNumber
          : clue.number < currentNumber) &&
        clue.direction == focusedDirection
    );
    if (diff < 0) {
      nextCluesInDirection = nextCluesInDirection.reverse();
    }
    let nextClue = nextCluesInDirection[Math.abs(diff) - 1];
    if (!nextClue) {
      onFlipDirection();
      nextClue = clues.filter((clue) => clue.direction == focusedDirection)[0];
    }
    const nextFocusedCell =
      sortedCellsInDirection.find(
        (cell) =>
          !cell.value && cell.clueNumbers[focusedDirection] == nextClue.number
      ) || {};
    focusedCellIndex = nextFocusedCell.index || 0;
  }

  function onMoveFocus(direction, diff) {
    if (focusedDirection != direction) {
      const dimension = direction == "across" ? "x" : "y";
      focusedDirection = direction;
    } else {
      const nextCell = getCellAfterDiff({
        diff,
        cells,
        direction,
        focusedCell,
      });
      if (!nextCell) return;
      onFocusCell(nextCell.index);
    }
  }

  function onFlipDirection() {
    const newDirection = focusedDirection === "across" ? "down" : "across";
    const hasClueInNewDirection = !!focusedCell["clueNumbers"][newDirection];
    if (hasClueInNewDirection) focusedDirection = newDirection;
  }

  function getToStorage() {
    let keyword;
    // if(isEmbeded == false){
      keyword = localStorage.getItem("keyword");
    // }

    if (keyword!=null) {
      return JSON.parse(keyword);
    }
    return [];
  }
  function setToStorage(data) {
    // if(isEmbeded == false){
      localStorage.setItem("keyword", JSON.stringify(data));
    // /}

  }

  // function onKeydown({ detail }) {
  
  //   const diff = detail === "Backspace" ? -1 : 1;
  //   const value = detail === "Backspace" ? "" : detail;
  //   onCellUpdate(focusedCellIndex, value, diff);
  // }

  function onKeydown({ detail }) {

    const diff = detail === "Backspace" ? -1 : 1;
    const value = detail === "Backspace" ? "" : detail;
    //   onCellUpdate(focusedCellIndex, value, diff);
    if (detail == "Backspace") {

      onCellUpdate(focusedCellIndex, "", -1, true);
      // return;
    }else{
      onCellUpdate(focusedCellIndex, value, diff);
      // return false;
    }
    return false;
  
  }

  function onClick() {
    isPuzzleFocused = element.contains(document.activeElement);
  }

  //ClueBar script start here
  $: focusedClueNumbers = focusedCell.clueNumbers || {};
  $: currentClue =
    clues.find(
      (c) =>
        c.direction === focusedDirection &&
        c.number === focusedClueNumbers[focusedDirection]
    ) || {};

  function onClueFocus({ direction, id }) {
    focusedDirection = direction;
    focusedCellIndex = cellIndexMap[id] || 0;
  }

  function onNextClue({ detail }) {
    let next = detail;
    if (next < 0) next = clues.length - 1;
    else if (next > clues.length - 1) next = 0;
    const { direction, id } = clues[next];
    onClueFocus({ direction, id });
  }

  ///ClueBar script end here

  function checkClues() {
    return clues.map((d) => {
      const index = d.index;
      const cellChecks = d.cells.map((c) => {
        const { value } = cells.find((e) => e.id === c.id);
        const hasValue = !!value;
        const hasCorrect = value === c.answer;
        return { hasValue, hasCorrect };
      });
      const isCorrect =
        cellChecks.filter((c) => c.hasCorrect).length === d.answer.length;
      const isFilled =
        cellChecks.filter((c) => c.hasValue).length === d.answer.length;
      return {
        ...d,
        isCorrect,
        isFilled,
      };
    });
  }

  function reset() {
    isRevealing = false;
    isChecking = false;
    focusedCellIndex = 0;
    focusedDirection = "across";
  }

  function onClear() {
    // if(isEmbeded == false){
      //here i am removing the localstorage won and keeptrying variables
      localStorage.removeItem('isKeepTrying');
      localStorage.removeItem('w');
      if (isMobile) {
        showKeyboard = true;
      }
    // }
    //this clearPuzzleNowActiveFirstBox i made to inital active first slot so user and enter directly letter instead of first click on slot and then enter it same i did in mount function inside the cell.svelete
    clearPuzzleNowActiveFirstBox();
    reset();
    if (revealTimeout) clearTimeout(revealTimeout);
    cells = cells.map((cell) => ({
      ...cell,
      value: "",
    }));
  }

  function onReveal() {
    if (revealed) return true;
    reset();
    cells = cells.map((cell) => ({
     
      ...cell,
      value: cell.answer,
    }));
    startReveal();
    if (isMobile) {
      showKeyboard = false;
    }
    clearPuzzleNowActiveFirstBox();
    onFocusCell(1);
    localStorage.setItem('isReveal','true');
    console.log('cells'+cells[0]);
    console.log('clues'+clues[0]);
    let arr = [];
    let index = 0;
    clues.map((row) => {
      if(row.direction == 'across'){
        row.cells.map((singleClueLetter) =>{
          arr.push({ index:index, value: singleClueLetter.answer });
          index++;
        });
      }
    });
    // alert('Revealed');
    setToStorage(arr);
    updateCellsWithKeywords();


 
  }

  function updateCellsWithKeywords(){
    
   let keyword = localStorage.getItem("keyword");  
      if (keyword !=null || alreadyFilledData.length) {
        alreadyFilledData = JSON.parse(keyword);
        const allCells = cells;
        for (const item of allCells) {
          const find = alreadyFilledData.find((data) => {
            return data.index === item.index;
          });
          if (find) {
            const value = find.value;
            cells[item.index].value = value;
          }
        }
      }
  }

  function onCheck() {
    isChecking = true;
  }

  function startReveal() {
    isRevealing = true;
    isChecking = false;
    if (revealTimeout) clearTimeout(revealTimeout);
    revealTimeout = setTimeout(() => {
      isRevealing = false;
    }, revealDuration + 250);
  }

  export function onToolbarEvent({ detail }) {
    if (detail === "clear") {
      onClear();
      // if(isEmbeded == false){
        localStorage.removeItem("keyword");
        localStorage.removeItem("timmer");
        localStorage.removeItem("start");
        localStorage.removeItem('w');
        localStorage.removeItem('isKeepTrying');
        localStorage.removeItem('isTryAgainPopup');
        localStorage.removeItem('isReveal');
      // }

       toolBarData.stopAndZero();
       toolBarData.startTimer();
      dispatch("keyEnabled", false);
    } else if (detail === "reveal") {
      onReveal();
    } else if (detail === "check") {
      onCheck();
    }
  }
</script>

<svelte:window on:click="{onClick}" />

<section
 style:font-family={fontFamily ? fontFamily : ''}
  class="puzzle {isEmbeded && isIpadDevice ? 'ipad-devices-margin-bottom':''}"
  class:stacked
  class:is-loaded="{isLoaded}"
  bind:this="{element}"
>
  <slot
    name="toolbar"
    onClear="{onClear}"
    onReveal="{onReveal}"
    onCheck="{onCheck}"
  >
    <Toolbar
      actions="{actions}"
      fontFamily = "{fontFamily}"
      bind:this="{toolBarData}"
      puzzleDetail="{puzzleDetail}"
      on:event="{onToolbarEvent}"
    />
  </slot>
  <svg viewBox="0 0 {w} {h}" class="{isIpadDevice && !isEmbeded ? 'ipad-puzzle-box ipad-screen-puzzle-width':''}">
    {#each cells as { x, y, value, answer, index, number, custom }}
      <Cell
        bind:onFocusSelf={clearPuzzleNowActiveFirstBox}
        x="{x}"
        y="{y}"
        fontFamily = "{fontFamily}"
        index="{index}"
        value="{value}"
        answer="{answer}"
        number="{number}"
        custom="{custom}"
        disabled="{disabled}"
        changeDelay="{isRevealing
          ? (revealDuration / cells.length) * index
          : 0}"
        isRevealing="{isRevealing}"
        isChecking="{isChecking}"
        isFocused="{focusedCellIndex == index && !isDisableHighlight}"
        isSecondarilyFocused="{secondarilyFocusedCells.includes(index) &&
          !isDisableHighlight}"
        onFocusCell="{onFocusCell}"
        onCellUpdate="{onCellUpdate}"
        onFocusClueDiff="{onFocusClueDiff}"
        onMoveFocus="{onMoveFocus}"
        onFlipDirection="{onFlipDirection}"
        onHistoricalChange="{onHistoricalChange}"
      />
    {/each}
  </svg>

  <div class="clues--stacked clue-bar-margin-top  {isIpadDevice && !isEmbeded ? 'ipad-screen-puzzle-width':''}">
    <ClueBar fontFamily = "{fontFamily}" currentClue="{currentClue}" on:nextClue="{onNextClue}" />
  </div>
</section>
<!-- fixed-bottom -->
<!-- margin-bottom -->
{#if keyboardVisible}
  <div class="keyboard {isEmbeded == false? 'fixed-bottom':''} {isMobile && isEmbeded ? 'display-none':''} {isIpadDevice && isEmbeded ? 'fixed-bottom':''}" fontFamily = "{fontFamily}">
    <Keyboard
      fontFamily = "{fontFamily}"
      layout="crossword"
      style="{keyboardStyle}"
      on:keydown="{onKeydown}"
    />
  </div>
{/if}

<style>
  .ipad-devices-margin-bottom{
    margin-bottom: 120px;
  }
  .ipad-screen{
    /* position: absolute !important;
    top: 100% !important;
    margin-top: 20px; */
  }

  /* .fixed-bottom{
    position: absolute !important;
    top: 100%;
  } */
  .margin-bottom{
    margin-bottom:20px;
  }
  .clues--stacked {
    margin-top: 20px;
  }
  section {
    position: sticky;
    top: 1em;
    order: 0;
    flex: 1;
    height: fit-content;
  }

  section.is-loaded.stacked {
    position: relative;
    top: auto;
    height: auto;
    order: -1;
  }

  svg {
    width: 100%;
    display: block;
    font-size: 1px;
    background: var(--main-color);
    border: 4px solid var(--main-color);
    box-sizing: border-box;
  }

  .keyboard {
    order: 3;
  }
  .ipad-puzzle-box{
      background: white;
    }
  
  /* .fix-keyboard{
    top: 570px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  } */
  @media only screen and (max-width: 720px) {
    section:not(.is-loaded) {
      position: relative;
      top: auto;
      height: auto;
      order: -1;
    }
    /* .fix-keyboard{
      position: unset !important;
    } */
  }
  @media only screen and (max-height: 850px) {
    .ipad-screen-puzzle-width{
      width: 100%;
    }
  }


  @media only screen and (max-height: 810px) {
    .ipad-screen-puzzle-width{
      width: 95%;
    }
  }



  @media only screen and (max-height: 780px) {
    .ipad-screen-puzzle-width{
      width: 88%;
    }
  }

  @media only screen and (max-height: 750px) {
    .ipad-screen-puzzle-width{
      width: 85%;
    }
  }

  @media only screen and (max-height: 730px) {
    .ipad-screen-puzzle-width{
      width: 80%;
    }
  }

  @media only screen and (max-height: 700px) {

    .ipad-screen-puzzle-width{
      width: 75%;
    }
  }


  @media only screen and (max-height: 680px) {
    .ipad-screen-puzzle-width{
      width: 70%;
    }
  }


  @media only screen and (max-height: 650px) {
    .ipad-screen-puzzle-width{
      width: 65%;
    }
  }


  @media only screen and (max-height: 620px) {
    .ipad-screen-puzzle-width{
      width: 60%;
    }
  }


  
  @media only screen and (max-height: 600px) {
    .ipad-screen-puzzle-width{
      width: 55%;
    }
  }

  @media only screen and (max-height: 580px) {
    .ipad-screen-puzzle-width{
      width: 50%;
    }
  }

  @media only screen and (max-height: 550px) {
    .ipad-screen-puzzle-width{
      width: 45%;
    }
  }

  @media only screen and (max-height: 530px) {
    .ipad-screen-puzzle-width{
      width: 40%;
    }
  }

  @media only screen and (max-height: 500px) {
    .ipad-screen-puzzle-width{
      width: 35%;
    }
  }

  @media only screen and (min-width: 721px) {
    .display-none{
      /* display: none !important; */
      /* position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1030; */
    }
  }
</style>
