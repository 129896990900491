export default function checkIpad() {
	const devices = {
		ios: () => navigator.userAgent.match(/iPad|iPod/i),
		ipadPro: () => (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad',

	};

	function isIpadPro() {
		var ratio = window.devicePixelRatio || 1;
		var screen = {
			width : window.screen.width * ratio,
			height : window.screen.height * ratio
		};
		return(screen.width === 2048 && screen.height === 2732) || (screen.width === 2732 && screen.height === 2048) || (screen.width === 1536 && screen.height === 2048) || (screen.width === 2048 && screen.height === 1536);
	}

	return devices.ios() || devices.ipadPro() || isIpadPro();
}
